import request from '@/utils/request'
const api = {

    // 获取所有平台仪器数据字典
    platform(params) {
        return request({
            url: '/api/platform/',
            method: 'get',
            params
        })
    },
    // 获取所有省份对应市区数据字典
    city(params) {
        return request({
            url: '/api/city/',
            method: 'get',
            params
        })
    },
    // 获取所有经销商数据字典
    customer(params) {
        return request({
            url: '/api/customer/',
            method: 'get',
            params
        })
    },
    // 获取所有的医院列表(取消分页)
    hospital_list(params) {
        return request({
            url: '/api/hospital_list/',
            method: 'get',
            params
        })
    },
    // 获取省份对应的医院
    province_hospital(params) {
        return request({
            url: '/api/province_hospital/',
            method: 'get',
            params
        })
    },
    // 物品申请获取平台对应的备件/耗材名称
    article(params) {
        return request({
            url: '/api/articleapplylist/article/',
            method: 'get',
            params
        })
    },

    // 经销商模糊匹配查询
    search_customer(params) {
        return request({
            url: '/api/search_customer/',
            method: 'get',
            params
        })
    },
    

    // 用量统计
    // 用量统计-省份列表
    province_stats_sum(params) {
        return request({
            url: '/api/province_stats_sum/',
            method: 'get',
            params
        })
    },
    // 用量统计-省份构成分析
    province_used_day_constitute(params) {
        return request({
            url: '/api/province_used_day_constitute/',
            method: 'get',
            params
        })
    },
    // 用量统计-省份时序分析
    province_use_day_timing(params) {
        return request({
            url: '/api/province_use_day_timing/',
            method: 'get',
            params
        })
    },
    // 用量统计-省份导出excle
    export_province_stats(params) {
        return request({
            url: '/api/export_province_stats/',
            method: 'get',
            params
        })
    },



    // 用量统计-医院统计列表
    hospital_stats_list(params) {
        return request({
            url: '/api/hospital_stats_list/',
            method: 'get',
            params
        })
    },
    // 用量统计-医院构成分析
    hospital_used_day_constitute(params) {
        return request({
            url: '/api/hospital_used_day_constitute/',
            method: 'get',
            params
        })
    },
    // 用量统计-医院时序分析
    hospital_used_day_timing(params) {
        return request({
            url: '/api/hospital_used_day_timing/',
            method: 'get',
            params
        })
    },
    // 用量统计-医院导出excle
    export_hospital_stats(params) {
        return request({
            url: '/api/export_hospital_stats/',
            method: 'get',
            params
        })
    },



    // 用量统计-仪器用量统计列表
    instrument_stats_list(params) {
        return request({
            url: '/api/instrument_stats_list/',
            method: 'get',
            params
        })
    },
    // 用量统计-仪器构成分析
    instrument_stats_constitute(params) {
        return request({
            url: '/api/instrument_stats_constitute/',
            method: 'get',
            params
        })
    },
    // 用量统计-仪器时序分析
    instrument_stats_timing(params) {
        return request({
            url: '/api/instrument_stats_timing/',
            method: 'get',
            params
        })
    },
     // 用量统计-仪器导出excle
     export_instrument_stats(params) {
        return request({
            url: '/api/export_instrument_stats/',
            method: 'get',
            params
        })
    },
    //全国用量统计
    national_usage_statistics(params) {
        return request({
            url: '/api/national_usage_statistics/',
            method: 'get',
            params
        })
    },
    // 全国统计展示数据
    // 用量统计-全国构成分析
    national_used_cons(params) {
        return request({
            url: '/api/national_used_cons/',
            method: 'get',
            params
        })
    },
    // 用量统计-全国时序分析
    national_used_timing(params) {
        return request({
            url: '/api/national_used_timing/',
            method: 'get',
            params
        })
    },


    // 销量统计
    //销量统计-省份的全国销量统计
    daily_trade_entire(params) {
        return request({
            url: '/api/daily_trade_entire/',
            method: 'get',
            params
        })
    },
    //销量统计-省份的销量统计列表
    districtDailyTradeSum(params) {
        return request({
            url: '/api/district_daily_trade_sum/',
            method: 'get',
            params
        })
    },
     // 省份销量统计全国构成分析
     daily_trade_entire_cons(params) {
        return request({
            url: '/api/daily_trade_entire_cons/',
            method: 'get',
            params
        })
    },
    // 省份销量统计省份构成分析
    district_daily_trade_constitute(params) {
        return request({
            url: '/api/district_daily_trade_constitute/',
            method: 'get',
            params
        })
    },
    // 销量统计-省份 全国时序分析
    daily_trade_entire_timing(params) {
        return request({
            url: '/api/daily_trade_entire_timing/',
            method: 'get',
            params
        })
    },
    // 销量统计- 省份时序分析
    district_daily_trade_timing(params) {
        return request({
            url: '/api/district_daily_trade_timing/',
            method: 'get',
            params
        })
    },

    // 销量统计-经销商的全国销量统计
    customer_daily_trade_entire(params) {
        return request({
            url: '/api/customer_daily_trade_entire',
            method: 'get',
            params
        })
    },
    // 统计经销商金额人份数占比
    customer_daily_trade_sum(params) {
        return request({
            url: '/api/customer_daily_trade_sum',
            method: 'get',
            params
        })
    },
    // 经销商销量统计构成分析-全国
    customer_daily_trade_constitute_entire(params) {
        return request({
            url: '/api/customer_daily_trade_constitute_entire',
            method: 'get',
            params
        })
    },
    // 经销商销量统计构成分析
    customer_daily_trade_constitute(params) {
        return request({
            url: '/api/customer_daily_trade_constitute',
            method: 'get',
            params
        })
    },
     // 经销商销量统计时序分析-全国
     customer_daily_trade_entire_timing(params) {
        return request({
            url: '/api/customer_daily_trade_entire_timing',
            method: 'get',
            params
        })
    },
    // 经销商销量统计时序分析
    customer_daily_trade_timing(params) {
        return request({
            url: '/api/customer_daily_trade_timing',
            method: 'get',
            params
        })
    },

    // 报告-根据平台聚合
    trade_by_platform(params) {
        return request({
            url: '/api/trade_by_platform',
            method: 'get',
            params
        })
    },
    // // 获取试剂对应的-试剂
    // testing(params) {
    //     return request({
    //         url: '/api/testing/',
    //         method: 'get',
    //         params
    //     })
    // },
    // 获取试剂对应的-试剂
    testing(params) {
        return request({
            url: '/api/dimension_testing/',
            method: 'get',
            params
        })
    },
    // 用于统计销量统计_报告试剂聚合数据字典
    reagent_class_type_dict(params) {
        return request({
            url: '/api/reagent_class_type_dict/',
            method: 'get',
            params
        })
    },
    // 获取试剂对应的-平台
    reagent_platform(params) {
        return request({
            url: '/api/reagent_platform/',
            method: 'get',
            params
        })
    },
    //销量统计-根据检测项目聚合 
    testing_daily_trade(params) {
        return request({
            url: '/api/testing_daily_trade/',
            method: 'get',
            params
        })
    },
    // 日成交量销量统计
    day_daily_trade_list(params) {
        return request({
            url: '/api/day_daily_trade_list/',
            method: 'get',
            params
        })
    },
    // 销量统计-日成交导出表格
    export_excel_day_daily_trade(params) {
        return request({
            url: '/api/export_excel_day_daily_trade/',
            method: 'get',
            // responseType: 'blob',
            params
        })
    },


    // 维修统计
    // 省份维修统计
    province_stats_repair(params) {
        return request({
            url: '/api/province_stats_repair',
            method: 'get',
            params
        })
    },
    // 医院
    hospital_stats_repair(params) {
        return request({
            url: '/api/hospital_stats_repair/',
            method: 'get',
            params
        })
    },

    // 仪器维修统计
    instrument_stats_repair(params) {
        return request({
            url: '/api/instrument_stats_repair/',
            method: 'get',
            params
        })
    },

    // 备件统计
    // 备件统计-批号
    stats_article_batch_list(params) {
        return request({
            url: '/api/stats_article_batch_list/',
            method: 'get',
            params
        })
    },
    // 备件统计-备件
    stats_article_list(params) {
        return request({
            url: '/api/stats_article_list/',
            method: 'get',
            params
        })
    },
    // 备件统计新接口
    article_reserve_list(params) {
        return request({
            url: '/api/article_reserve_list/',
            method: 'get',
            params
        })
    },

    // 样本检测统计-历史
    positive_rate(params) {
        return request({
            url: '/api/positive_rate/',
            method: 'get',
            params
        })
    },
    // 样本检测统计-今年
    now_positive_rate(params) {
        return request({
            url: '/api/now_positive_rate/',
            method: 'get',
            params
        })
    },
    // 统计全国年度月度同比分析
    tbdaily_trade_entire(params) {
        return request({
            url: '/api/tbdaily_trade_entire/',
            method: 'get',
            params
        })
    },
    // 统计全国年度月度同比分析
    top(params) {
        return request({
            url: '/api/top/',
            method: 'get',
            params
        })
    },
    // 统计同比近三年各省用量
    last_three_year_tbdosage(params) {
        return request({
            url: '/api/last_three_year_tbdosage/',
            method: 'get',
            params
        })
    },
    // 统计各省份销售订单已完成的金额和数量
    area_orders(params) {
        return request({
            url: '/api/area_orders/',
            method: 'get',
            params
        })
    },

	// 业绩目标展示
     sale_target(params) {
        return request({
            url: '/api/sale_target/',
            method: 'get',
            params
        })
    },
     // 销售分析
     sales_analysis(params) {
        return request({
            url: '/api/sales_analysis/',
            method: 'get',
            params
        })
    },
     // 某日销售详情
     sales_constitute_analysis(params) {
        return request({
            url: '/api/sales_constitute_analysis/',
            method: 'get',
            params
        })
    },

}
export default api