export default {

  mounted() {
    window.addEventListener('resize', this.handlerResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handlerResize);
  },
  methdos: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
  }
}